import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

import _styles from './sales-letter-avatar.module.scss';

const SalesLetterAvatar = () => {
  const imageData = useStaticQuery(
    graphql`
      query {
        mugshot: file(relativePath: { eq: "mugshot.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <div className={`${_styles.container}`}>
      <Img
        className={`${_styles.avatar}`}
        fluid={imageData.mugshot.childImageSharp.fluid}
      />
    </div>
  );
};

export default SalesLetterAvatar;
