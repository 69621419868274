import React from 'react';

import _styles from './bullet-title.module.scss';

const BulletTitle = () => {
  return (
    <h2 className={`${_styles.title}`}>Inside The Guide</h2>
  );
};

export default BulletTitle;