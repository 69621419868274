import React from 'react';

import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const EmailCaptureFormErrors = ({ errors }) => {
  return (
    <div
      className={`${_themeFormStyles.fieldset} ${_utils.mt32x} ${_utils.mb32x}`}
    >
      <div className={`${_utils.pr16x} ${_utils.pl16x}`}>
        <div
          className={`${_themeFormStyles.errors} ${_utils.pt16x} ${_utils.pb16x}`}
        >
          {errors.server.message ? (
            <p className={`${_themeFormStyles.errorMessage}`}>
              {errors.server.message}
            </p>
          ) : (
            <>
              <p className={`${_themeFormStyles.errorMessage}`}>
                Ohh no! Something went wrong. Please refresh your browser and
                try again...
              </p>
              <p className={`${_themeFormStyles.errorMessage}`}>
                If the problem persists, send an email to info@kalanso.com
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailCaptureFormErrors;
