import React from 'react';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';

import AboveFoldBackground from '../../art-direction/AboveFoldBackgroundV2';

const AboveFoldSection = ({ children }) => {
  return (
    <Container
      fullScreen={true}
      Background={AboveFoldBackground}
    >
      {children}
    </Container>
  );
};

export default AboveFoldSection;
