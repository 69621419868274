import React from 'react';

import _styles from './sales-letter-title.module.scss';

const SalesLetterTitle = () => {
  return (
    <h1 className={`${_styles.title}`}>Get The Insider Tips and Strategies For Creating Robust, Balanced Soil (No Matter What Zone You Live In)</h1>
  );
};

export default SalesLetterTitle;
