import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import EcoverTitle from './EcoverTitle';
import EcoverImage from './EcoverImage';

import ResponsiveButtonSetRow from '../../shared/ResponsiveButtonSetRow';

import _styles from './ecover.module.scss';

const EcoverContent = () => {
  return (
    <>
      <Row className={`${_styles.titleRow}`}>
        <Col>
          <EcoverTitle />
        </Col>
      </Row>

      <Row className={`${_styles.imageRow}`}>
        <Col grow="1" style={{ maxWidth: '700px' }}>
          <EcoverImage />
        </Col>
      </Row>

      <ResponsiveButtonSetRow />
    </>
  );
};

export default EcoverContent;
