import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

import formatClassNames from 'gatsby-theme-kalanso-ui/src/utils/formatClassNames';

import _themeArtDirectionStyles from './art-direction.module.scss';


/**
 * Background Image
 *
 * Purpose
 * =======
 * - Aspect ratio for portrait images is 1:2, landscape images is 1.61
 * - Formula for aspect ratio is: (width / height)
 *
 * Implementation Steps
 * ====================
 * [] Add 7 background images with the
 *    following scales to the `src/images` folder:
 *
 *   - 360x720   (portrait)
 *   - 400x800   (portrait)
 *   - 500x1000  (portrait)
 *   - 360x223   (landscape)
 *   - 720x446   (landscape)
 *   - 1024x636  (landscape)
 *   - 1440x892  (landscape)
 *   - 2160x1338 (landscape)
 *   - 2560x1586 (landscape)
 * 
 * Naming Convention
 * =================
 * 
 *  `[filename]--[orientation]--[pixel dimensions]`
 *  `hero-01--landscape--400x800`
 *  `running-on-the-beach--portrait--2560x1586`
 *
 * [] In Affinity Photo:
 *
 *   - Open the default image
 *   - Use the crop tool
 *   - Set `Mode: Custom Ratio`
 *   - Enter the dimensions
 */

const AboveFoldBackground = ({ children, className }) => {
  const {
    // Portrait (1:2 aspect ratio)
    portrait360x720,
    portrait400x800,
    portrait500x1000,
    portrait768x1024,

    // Landscape (1.61 aspect ratio)
    landscape360x223,
    landscape720x446,
    landscape1024x636,
    landscape1440x892,
    landscape2160x1338,
    landscape2560x1586,
  } = useStaticQuery(
    graphql`
      query {
        portrait360x720: file(
          relativePath: { eq: "above-fold-v2--portrait--360x720.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portrait400x800: file(
          relativePath: { eq: "above-fold-v2--portrait--400x800.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portrait500x1000: file(
          relativePath: { eq: "above-fold-v2--portrait--500x1000.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portrait768x1024: file(
          relativePath: { eq: "above-fold-v2--portrait--768x1024.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape360x223: file(
          relativePath: { eq: "above-fold-v2--landscape--360x223.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape720x446: file(
          relativePath: { eq: "above-fold-v2--landscape--720x446.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 720, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape1024x636: file(
          relativePath: { eq: "above-fold-v2--landscape--1024x636.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape1440x892: file(
          relativePath: { eq: "above-fold-v2--landscape--1440x892.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape2160x1338: file(
          relativePath: { eq: "above-fold-v2--landscape--2160x1338.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2160, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape2560x1586: file(
          relativePath: { eq: "above-fold-v2--landscape--2560x1586.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    portrait360x720.childImageSharp.fluid,
    {
      ...portrait400x800.childImageSharp.fluid,
      media: '(min-width: 400px)',
    },
    {
      ...portrait500x1000.childImageSharp.fluid,
      media: '(min-width: 500px)',
    },
    {
      ...portrait768x1024.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...landscape360x223.childImageSharp.fluid,
      media: `(min-width: 361px) and (device-aspect-ratio: ${landscape360x223.childImageSharp.fluid.aspectRatio})`,
    },
    {
      ...landscape720x446.childImageSharp.fluid,
      media: `(min-width: 721px) and (device-aspect-ratio: ${landscape720x446.childImageSharp.fluid.aspectRatio})`,
    },
    {
      ...landscape1024x636.childImageSharp.fluid,
      media: `(min-width: 1050px)`,
    },
    {
      ...landscape1440x892.childImageSharp.fluid,
      media: '(min-width: 1441px)',
    },
    {
      ...landscape2160x1338.childImageSharp.fluid,
      media: '(min-width: 2161px)',
    },
    {
      ...landscape2560x1586.childImageSharp.fluid,
      media: '(min-width: 2560px)',
    },
  ];

  return (
    <BackgroundImage
      Tag={`section`}
      id={`above-fold-v2-bg`}
      className={`${formatClassNames([
        _themeArtDirectionStyles.container,
        className,
      ])}`}
      fluid={sources}
    >
      {children}
    </BackgroundImage>
  );
};

AboveFoldBackground.propTypes = {
  className: PropTypes.string,
};

AboveFoldBackground.defaultProps = {
  className: '',
};

export default AboveFoldBackground;
