import React from 'react';

import _styles from './bullet-headline.module.scss';

const BulletHeadline = () => {
  return (
    <h3 className={`${_styles.headline}`}>
      Here's a taste of what you'll find in "The Insider's Guide To Creating
      Robust Soil Outdoor Gardeners Dream Of"
    </h3>
  );
};

export default BulletHeadline;
