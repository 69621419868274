import React from 'react'

import _utils from '../../styles/utils.module.scss';

const HiddenAboveDesktop = ({ children }) => {
  return (
    <div className={`${_utils.hiddenAboveDesktop}`}>
      {children}
    </div>
  )
}

export default HiddenAboveDesktop;