import { useEffect } from 'react';
import { navigate } from 'gatsby';
import useAppState from '../../use-app-state';

const useOptinApiSuccess = ({ success }) => {

  const { requestInsidersGuide } = useAppState();

  useEffect(() => {
    if (success) {
      requestInsidersGuide();
      navigate('/access/insiders-guide');
    }
  }, [success, requestInsidersGuide]);
};

export default useOptinApiSuccess;
