import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

import _styles from './footer-body.module.scss';

const FooterBody = () => {
  return (
    <Row className={`${_styles.row}`}>
      <Col className={`${_styles.col}`}>
        <Footer />
      </Col>
    </Row>
  );
};

export default FooterBody;
