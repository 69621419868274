import React from 'react';
import _styles from './optin-subheadline.module.scss';

const OptinSubheadline = () => {
  return (
    <>
      <p className={`${_styles.subheadline}`}>
        <strong>Love Your Garden</strong>
      </p>
      <p className={`${_styles.subheadline}`}>
        <strong>Defeat Bad Soil</strong>
      </p>
      <p className={`${_styles.subheadline}`}>
        <strong>Reap The Rewards!</strong>
      </p>
    </>
  );
};

export default OptinSubheadline;
