import React from 'react';
import _styles from './optin-headline.module.scss';

const OptinFormHeadline = () => {
  return (
    <h4 className={`${_styles.headline}`}>
      "Insider's Guide To Creating The Robust Soil Every Outdoor Gardener Needs"
    </h4>
  );
};

export default OptinFormHeadline;
