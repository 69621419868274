import React from 'react';

import _styles from './above-fold-title.module.scss';

const AboveFoldTitle = () => {
  return (
    <h1 className={`${_styles.title}`}>
      Read This If You Want To Know How To Make Your Soil Better, <strong>Even If You Never Thought You Could!</strong>
    </h1>
  );
};

export default AboveFoldTitle;
