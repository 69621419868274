import React from 'react'

import _utils from '../../styles/utils.module.scss';

const VisibleAboveDesktop = ({ children }) => {
  return (
    <div className={`${_utils.visibleAboveDesktop}`}>
      {children}
    </div>
  )
}

export default VisibleAboveDesktop;