import { useEffect, useCallback } from 'react';

const useOptinApiErrors = ({ errors, setError }) => {
  const setErrorCb = useCallback(
    () => setError('server', { type: 'manual' }),
    [setError],
  );

  useEffect(() => {
    if (errors) {
      setErrorCb();
    }
  }, [errors, setErrorCb]);
};

export default useOptinApiErrors;
