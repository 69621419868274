import React from 'react';

import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';
import _styles from './above-fold-headline.module.scss';

const AboveFoldHeadline = () => {
  return (
    <h2 className={`${_styles.headline}`}>
      <Highlighted>
        The Insider’s Guide To Creating Robust Soil Outdoor Gardeners Dream Of
      </Highlighted>
    </h2>
  );
};

export default AboveFoldHeadline;
