import React from 'react';

import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import _bulletStyles from './bullet-item.module.scss';
import _styles from './bullet-list.module.scss';

const BulletList = () => {
  return (
    <ul className={`${_styles.list}`}>
      <li className={`${_bulletStyles.item}`}>
        <div className={`${_bulletStyles.title}`}>
          <div className={`${_bulletStyles.circle}`}>
            <i className={`fas fa-user-secret`}></i>
          </div>
          <span className={_bulletStyles.label}>What you measure grows</span>
        </div>
        <p className={`${_bulletStyles.subtitle}`}>
          Want a more impressive harvest? It's really simple. Pay attention to
          this one piece of soil data before you decide what to plant.{' '}
          <Highlighted>
            <strong>TIP #1</strong>
          </Highlighted>
        </p>
      </li>

      <li className={`${_bulletStyles.item}`}>
        <div className={`${_bulletStyles.title}`}>
          <div className={`${_bulletStyles.circle}`}>
            <i className={`fas fa-user-secret`}></i>
          </div>
          <span className={_bulletStyles.label}>Garden with nature</span>
        </div>
        <p className={`${_bulletStyles.subtitle}`}>
          So what does that actually look like? I'm happy to share it with
          you... because this clever approach can help you create
          low-maintenance soil... and a low-maintenance garden.{' '}
          <Highlighted>
            <strong>TIP #2</strong>
          </Highlighted>
        </p>
      </li>

      <li className={`${_bulletStyles.item}`}>
        <div className={`${_bulletStyles.title}`}>
          <div className={`${_bulletStyles.circle}`}>
            <i className={`fas fa-user-secret`}></i>
          </div>
          <span className={_bulletStyles.label}>Just breathe</span>
        </div>
        <p className={`${_bulletStyles.subtitle}`}>
          Sometimes doing the simpliest things yield the biggest results, right?
          You can make this one improvement to your soil in a heartbeat.{' '}
          <Highlighted>
            <strong>TIP #3</strong>
          </Highlighted>
        </p>
      </li>

      <li className={`${_bulletStyles.item}`}>
        <div className={`${_bulletStyles.title}`}>
          <div className={`${_bulletStyles.circle}`}>
            <i className={`fas fa-user-secret`}></i>
          </div>
          <span className={_bulletStyles.label}>Worst case scenario</span>
        </div>
        <p className={`${_bulletStyles.subtitle}`}>
          Is your soil really tough to work with? Here's some reasons why, plus
          one thing you can do to fix it.{' '}
          <Highlighted>
            <strong>TIP #4</strong>
          </Highlighted>
        </p>
      </li>
      <li className={`${_bulletStyles.item}`}>
        <div className={`${_bulletStyles.title}`}>
          <div className={`${_bulletStyles.circle}`}>
            <i className={`fas fa-user-secret`}></i>
          </div>
          <span className={_bulletStyles.label}>
            Are you growing the wrong plants?
          </span>
        </div>
        <p className={`${_bulletStyles.subtitle}`}>
          There's no one-soil-fits-all solution... do this to find the perfect
          plant/soil match made in heaven.{' '}
          <Highlighted>
            <strong>TIP #5</strong>
          </Highlighted>
        </p>
      </li>
    </ul>
  );
};

export default BulletList;
