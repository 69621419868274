import React from 'react';

import Layout from 'gatsby-theme-kalanso-ui/src/components/Layout';
import SEO from 'gatsby-theme-kalanso-ui/src/components/SEO';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';
import HiddenAboveDesktop from 'gatsby-theme-kalanso-ui/src/components/responsive/HiddenAboveDesktop';

import ErrorBoundary from 'gatsby-theme-kalanso-ui/src/components/forms/ErrorBoundary';
import OptinContent from './OptinContent';

import AboveFoldSection from './AboveFoldSection';
import AboveFold from './AboveFold';

import SalesLetterSection from './SalesLetterSection';
import SalesLetter from './SalesLetter';

import EcoverSection from './EcoverSection';
import EcoverContent from './EcoverContent';

import BulletsSection from './BulletsSection';
import BulletsContent from './BulletsContent';

import FooterSection from '../../shared/FooterSection';
import FooterBody from '../../shared/FooterBody';

const seoTitle = `The Insider's Guide To Creating Robust Soil Outdoor Gardeners Dream Of`;

const InsidersGuideOptinPage = () => {
  return (
    <>
      <SEO title={seoTitle} />
      <Layout>
        <AboveFoldSection>
          <AboveFold />
        </AboveFoldSection>

        <HiddenAboveDesktop>
          <br />
          <Row>
            <Col>
              <ErrorBoundary>
                <OptinContent />
              </ErrorBoundary>
            </Col>
          </Row>
        </HiddenAboveDesktop>

        <SalesLetterSection>
          <SalesLetter />
        </SalesLetterSection>

        <EcoverSection>
          <EcoverContent />
        </EcoverSection>

        <BulletsSection>
          <BulletsContent />
        </BulletsSection>

        <EcoverSection>
          <EcoverContent />
        </EcoverSection>

        <FooterSection>
          <FooterBody />
        </FooterSection>
      </Layout>
    </>
  );
};

export default InsidersGuideOptinPage;
