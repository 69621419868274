import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useTrackingCodes } from 'kalanso-plugin-hooks';

import useOptinApi from './use-optin-api';
import useOptinApiSuccess from './use-optin-api-success';
import useOptinApiErrors from './use-optin-api-errors';

import TextField from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/TextField';
import Honeypot from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Honeypot';
import SubmitButton from '../../shared/SubmitButton';

import OptinFormErrors from './OptinFormErrors';

import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _styles from './optin-form.module.scss';

const errorMessageRenderer = (errors) => {
  errors.forEach((err) => {
    switch (err.code) {
      case 'string.empty':
        err.message = 'Enter Your Email And Get Your Free Copy';
        break;
      default:
        break;
    }
  });
  return errors;
};

const schema = Joi.object({
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .max(255)
    .error(errorMessageRenderer),
  honey: Joi.string().min(0).max(0).allow('').required(),
});

const OptinForm = ({ formId }) => {
  const formMethods = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const formValues = formMethods.watch();

  const metadata = useTrackingCodes();

  const [optinApi, optin] = useOptinApi({
    ...formValues,
    metadata,
  });

  // Handle success
  useOptinApiSuccess(optinApi);

  // Handle errors
  useOptinApiErrors({ ...optinApi, setError: formMethods.setError });

  return (
    <>
      {formMethods.errors.server && <OptinFormErrors {...formMethods} />}

      <form id={formId} className="js-insiders-guide-form" onSubmit={formMethods.handleSubmit(optin)}>
        <div className={`${_themeFormStyles.fieldset}`}>
          <TextField
            id={`${formId}-email`}
            label="Enter Your Email *"
            name="email"
            type="email"
            fieldCss={`${_utils.ml0x} ${_utils.mr0x}`}
            inputCss={`${_styles.textfield}`}
            errorCss={`${_styles.error}`}
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <Honeypot
            honeyId={`${formId}-honey`}
            register={formMethods.register}
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <SubmitButton
            disabled={optinApi.pending}
            pending={optinApi.pending}
            pendingText="Preparing your free copy..."
            idleText="YES! I Want My Free Copy"
            idleIcon="fa-arrow-right"
          />
        </div>
      </form>
    </>
  );
};

OptinForm.propTypes = {
  formId: PropTypes.string,
};

OptinForm.defaultProps = {
  formId: 'optin-form',
};

export default OptinForm;
