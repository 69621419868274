import React from 'react';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';

const EcoverSection = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default EcoverSection;
