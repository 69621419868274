import React from 'react';
import _styles from './optin-title.module.scss';

const OptinFormTitle = () => {
  return (
    <h3 className={`${_styles.title}`}>
      Enter your best email below and I'll send you a FREE copy of my new
      Report...
    </h3>
  );
};

export default OptinFormTitle;
