import { useContext, useCallback } from 'react';
import { AppStateContext, actions } from './AppStateProvider';

const useAppState = () => {
  const [state, dispatch] = useContext(AppStateContext);

  const requestInsidersGuide = useCallback(() => {
    dispatch({ type: actions.INSIDERS_GUIDE_REQUESTED });
  }, [dispatch]);

  return {
    ...state,
    requestInsidersGuide
  };
};

export default useAppState;
