import React from 'react';

import Card from 'gatsby-theme-kalanso-ui/src/components/card/Card';

import OptinFormTitle from './OptinFormTitle';
import OptinFormHeadline from './OptinFormHeadline';
import OptinSubheadline from './OptinSubheadline';
import OptinForm from './OptinForm';

import _styles from './optin.module.scss';

const OptinContent = ({ formId = 'mobile' }) => {
  return (
    <>
      <Card className={`${_styles.card}`}>
        <OptinFormTitle />
        <OptinFormHeadline />
        <br />
        <OptinForm formId={formId} />
      </Card>
      <br />
      <OptinSubheadline />
    </>
  );
};

export default OptinContent;
