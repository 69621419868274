import React from 'react';
import PropTypes from 'prop-types';

import ScrollIntoView from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/ScrollIntoView';

import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';

const Button = ({ selector, buttonId, className, type, size, children }) => {
  return (
    <ScrollIntoView selector={selector}>
      <button
        id={buttonId}
        type="button"
        className={`${_buttonStyles.button} ${_buttonStyles[type]} ${_buttonStyles[size]} ${className}`}
      >
        {React.Children.count(children) > 0 ? children : 'Click Here'}
      </button>
    </ScrollIntoView>
  );
};

Button.propTypes = {
  buttonId: PropTypes.string.isRequired,
  selector: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  selector: '#desktop',
  type: 'secondary',
  size: 'largeV2',
};

export default Button;
