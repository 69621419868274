import React from 'react';

import _styles from './ecover-title.module.scss';

const EcoverTitle = () => {
  return (
    <h2 className={`${_styles.title}`}>
      Claim Your Free Copy Today!
    </h2>
  );
};

export default EcoverTitle;
