import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import BulletTitle from './BulletTitle';
import BulletHeadline from './BulletHeadline';
import BulletList from './BulletList';

import _styles from './bullets.module.scss';

const Bullets = () => {
  return (
    <>
      <br/>
      <Row className={`${_styles.titleRow}`}>
        <Col>
          <BulletTitle />
        </Col>
      </Row>

      <Row className={`${_styles.headlineRow}`}>
        <Col>
          <BulletHeadline />
        </Col>
      </Row>

      <Row className={`${_styles.listRow}`}>
        <Col>
          <BulletList />
        </Col>
      </Row>
    </>
  );
};

export default Bullets;
