import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import HiddenAboveDesktop from 'gatsby-theme-kalanso-ui/src/components/responsive/HiddenAboveDesktop';
import VisibleAboveDesktop from 'gatsby-theme-kalanso-ui/src/components/responsive/VisibleAboveDesktop';

import ButtonContainer from './ButtonContainer';
import Button from './Button';

import _styles from './responsive-button-set-row.module.scss';

const ResponsiveButtonSetRow = () => {
  return (
    <Row className={`${_styles.row}`}>
      <Col>
        <ButtonContainer>
          <HiddenAboveDesktop>
            <Button buttonId="mobile-click" selector="#mobile">
              YES! Send Me My Free Copy!
            </Button>
          </HiddenAboveDesktop>

          <VisibleAboveDesktop>
            <Button buttonId="desktop-click" selector="#desktop">
              YES! Send Me My Free Copy!
            </Button>
          </VisibleAboveDesktop>
        </ButtonContainer>
      </Col>
    </Row>
  );
};

export default ResponsiveButtonSetRow;
