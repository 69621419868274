import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import SalesLetterTitle from './SalesLetterTitle';
import SalesLetterCopy from './SalesLetterCopy';

import ResponsiveButtonSetRow from '../../shared/ResponsiveButtonSetRow';

import _styles from './sales-letter.module.scss';

const SalesLetter = () => {
  return (
    <>
      <Row className={`${_styles.titleRow}`}>
        <Col>
          <SalesLetterTitle />
        </Col>
      </Row>

      <Row className={`${_styles.copyRow}`}>
        <Col>
          <SalesLetterCopy />
        </Col>
      </Row>

      <ResponsiveButtonSetRow />
    </>
  );
};

export default SalesLetter;
