import React from 'react';

import SalesLetterAvatar from './SalesLetterAvatar';

import _styles from './sales-letter-copy.module.scss';

const SalesLetterCopy = () => {
  return (
    <>
      <br />

      <div className={`${_styles.avatarBlock}`}>
        <div>
          <p className={`${_styles.paragraph}`}>
            From The Desk Of Pat White
            <br />
            Zone 6b
          </p>
          <br />
          <p className={`${_styles.paragraph}`}>Dear Outdoor Gardener,</p>
          <br />
          <p className={`${_styles.paragraph}`}>
            For the past 5 years, I’ve helped folks like you all over the
            country make their soil better. It’s been a long journey.
          </p>
          <br />
          <p className={`${_styles.paragraph}`}>
            I started not knowing <strong>ABSOLUTELY ZERO</strong> about soil...
          </p>
          <br />
          <p className={`${_styles.paragraph}`}>
            But through lots of hard work, study, and failed attempts, I've
            gotten to the point where I've helped thousands of outdoor
            gardeners, and some of the most prestigeous landscapes in the
            country, make their soil better.
          </p>
          <br />
          <p className={`${_styles.paragraph}`}>
            And through that journey I’ve learned a thing or two about what
            works and what doesn’t.
          </p>
          <br />
          <p className={`${_styles.paragraph}`}>And here’s what I know...</p>
          <br />
          <p className={`${_styles.paragraph}`}>
            Every day you’re out there trying to make your garden a little bit better.
          </p>
          <br />
        </div>

        <SalesLetterAvatar />
      </div>

      <p className={`${_styles.paragraph}`}>
        There are certain plants you love... and you really want them to thrive
        in your yard.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        You find inspiration from others, and see how they do it... but
        sometimes it's hard applying the lessons of other people's successes to
        your unique situation.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>And when it comes to the soil...</p>
      <br />
      <p className={`${_styles.paragraph}`}>
        Things can get even more frustrating.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        There’s a lot of variables to consider, right?
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        AND starts to feel like a total waste of your time. Right?
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        And more often than not you wonder if you should even bother with the
        soil.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>Like you, I VALUE my time.</p>
      <br />
      <p className={`${_styles.paragraph}`}>And the way I see it...</p>
      <br />
      <p className={`${_styles.paragraph}`}>
        Give me what I need to know NOW just so I know I’m on the right path to
        getting the soil conditions right!
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        In and out. Please don’t waste my time.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>Are you the same way?</p>
      <br />
      <p className={`${_styles.paragraph}`}>I thought so.</p>
      <br />
      <p className={`${_styles.paragraph}`}>
        Because that’s one of the big reasons I put together this quick little
        Insider’s Guide...
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        It's short and sweet. To. The. Point.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        There’s ideas in it you probably haven’t considered before. And maybe
        some you have.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>
        And it’s a great way to turn off the overwhelm, take a moment to check
        in and know you’re on the right path.
      </p>
      <br />
      <p className={`${_styles.paragraph}`}>Claim your FREE copy today!</p>
      <br />
      <p className={`${_styles.paragraph}`}>Here’s to healthy, growing soil.</p>
      <br />
      <p className={`${_styles.paragraph}`}>Pat</p>
      <br />
    </>
  );
};

export default SalesLetterCopy;
