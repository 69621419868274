import React from 'react';
import PropTypes from 'prop-types';

import SecondaryButton from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/SecondaryButton';
import ButtonWrapper from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/ButtonWrapper';
import ButtonText from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/ButtonText';
import ButtonSubtext from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/ButtonSubText';

import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const SubmitButton = ({
  pending,
  disabled,
  idleText,
  idleIcon,
  idleSubtext,
  pendingText,
  pendingIcon,
  pendingSubtext,
}) => {
  return (
    <ButtonWrapper
      className={`${_utils.textCenter}`}
    >
      <SecondaryButton
        className={`${_utils.pt16x} ${_utils.pb16x} ${_utils.fullWidth} ${
          disabled ? _buttonStyles.disabled : ''
        }`}
        disabled={disabled}
        type="submit"
      >
        <ButtonText className={`${_utils.fontSize600} ${_utils.mb4x}`}>
          <span className={`${pending ? '' : _utils.hidden}`}>
            <i className={`fas ${pendingIcon}`}></i>
          </span>
          <span className={`${pending ? _utils.hidden : ''}`}>
            <i className={`fas ${idleIcon}`}></i>
          </span>
          <span>
            {pending ? pendingText : idleText}
          </span>
        </ButtonText>

        {/* Subtext for idle state */}
        {!pending && Boolean(idleSubtext) && (
          <ButtonSubtext>
            <em>{idleSubtext}</em>
          </ButtonSubtext>
        )}

        {/* Subtext for pending state */}
        {pending && Boolean(pendingSubtext) && (
          <ButtonSubtext>
            <em>{pendingSubtext}</em>
          </ButtonSubtext>
        )}
      </SecondaryButton>
    </ButtonWrapper>
  );
};

SubmitButton.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  pendingText: PropTypes.string,
  pendingSubtext: PropTypes.string,
  pendingIcon: PropTypes.string,
  idleText: PropTypes.string,
  idleSubtext: PropTypes.string,
  idleIcon: PropTypes.string,
};

SubmitButton.defaultProps = {
  pending: false,
  disabled: false,
  pendingText: 'Pending...',
  pendingSubtext: '',
  pendingIcon: 'fa-spinner-third fa-spin',
  idleText: 'Idle...',
  idleSubtext: '',
  idleIcon: 'fa-arrow-right',
};

export default SubmitButton;
