import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const EcoverImage = () => {
  const imageData = useStaticQuery(
    graphql`
      query {
        ecover: file(relativePath: { eq: "insiders-guide--2160x1200.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <Img fluid={imageData.ecover.childImageSharp.fluid} />
  );
};

export default EcoverImage;
