import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import HiddenAboveDesktop from 'gatsby-theme-kalanso-ui/src/components/responsive/HiddenAboveDesktop'

import ButtonContainer from '../../shared/ButtonContainer';
import Button from '../../shared/Button';

import AboveFoldTitle from './AboveFoldTitle';
import AboveFoldHeadline from './AboveFoldHeadline';

import ErrorBoundary from 'gatsby-theme-kalanso-ui/src/components/forms/ErrorBoundary';
import OptinContent from './OptinContent';

import _styles from './above-fold.module.scss';

const AboveFold = () => {
  return (
    <>
      <Row className={`${_styles.titleRow}`}>
        <Col grow="1" className={`${_styles.titleCol}`}>
          <AboveFoldTitle />
        </Col>
      </Row>

      <Row className={`${_styles.headlineRow}`}>
        <Col grow="1" className={`${_styles.headlineCol}`}>
          <AboveFoldHeadline />
        </Col>
      </Row>

      <Row className={`${_styles.ctaRow}`}>
        <Col grow="1" className={`${_styles.ctaCol}`}>
          <ButtonContainer>
            <HiddenAboveDesktop>
              <Button buttonId="mobile-click" selector="#mobile">
                YES! I Want To Claim My Free Copy Now!
              </Button>
            </HiddenAboveDesktop>
          </ButtonContainer>
        </Col>
      </Row>

      <Row className={`${_styles.formRow}`}>
        <Col grow="1" className={`${_styles.formCol}`}>
          <div className={`${_styles.formContainer}`}>
            <ErrorBoundary>
              <OptinContent formId="desktop" />
            </ErrorBoundary>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AboveFold;
